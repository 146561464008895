<template>
  <div>
    <div class="w1200">
      <div class="title">关于我们</div>
      <p class="f18 sp">
        店教授（Professor Dian）是安徽云蓝信息科技有限公司旗下的知名软件品牌，拥有收银系统、云后台、小程序、AI收银、店长App、微店、营销通CRM、智能厨显等产品。它是国内较早基于云架构，实现跨平台（Android/iOS/Windows），并与手机实时通讯互联的门店信息化Saas管理系统。产品历经5年打磨，满足了电脑收银、手机收银、平板收银、AI收银、自助收银、半自助收银、无人货架、扫码购等应用场景，实现了集商品、会员、员工、进销存、货流、营销、财务、供应链、数据和网店管理于一体的智慧门店管理系统。公司员工近100人，研发骨干均来自AT&T、科大讯飞、阿里等知名企业，市场成员多为业内资深人士和企业顾问。
      </p>
      <p class="f18 sp">
        正是基于成熟的产品框架和完整的销售及技术支援网络，“店教授”现已成为全国销量名列前茅的新一代收银系统。截止当前，店教授已在全国拥有10万+注册用户，服务覆及零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等多个行业。公司在全国拥有300+合作商，市场遍及30+城市。
      </p>
      <div style="margin-bottom:100px">
        <p class="f18 sp">
        【公司发展历程】
        </p>
        <p class="f18 sp">
            2018年，店教授收银系统面世
        </p>
        <p class="f18 sp">
           2018年3月 发布云后台&iPad&安卓三大版本收银系统，是国内为数不多的跨平台收银系统
        </p>
        <p class="f18 sp">
            2019年5月 首款APP面世，加速移动信息化发展
        </p>
        <p class="f18 sp">
            2019年9月研制店教授手机收银系统，开启移动支付元年
        </p>
        <p class="f18 sp">
            2019年12 外拓产品线，接入团购，支持多元支付
        </p>
        <p class="f18 sp">
            2020年 探索科技，研发AI人工智能收银研发
        </p>
        <p class="f18 sp">
            2021年 助力新零售，发布图像识别、人脸识别会员、自助收银技术
        </p>
        <p class="f18 sp">
            2022年4月 AI慧眼收银，带给消费者非同一般的科技体验
        </p>
        <p class="f18 sp">
            2022年 5月上线云财务、供应链、直播、社区团购等多平台智能方案
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
  font-size: 40px;
  margin: 40px 0 20px 0;
  color: #ff6727;
}
.sp{
    text-indent: 2em;
    line-height: 30px;
    margin-bottom: 20px;
}
</style>